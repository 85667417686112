<template>
  <div class="plate">
    <div class="parking">
      <img src="@/assets/parking.png" alt="" srcset="">
    </div>
    <div class="forms">
      <input type="number" v-model="phone" placeholder="请输入您的手机号">
      <div class="item">
        <input type="number" v-model="code" placeholder="请输入验证码">
        <button class="getCode" @click="getCode">获取验证码</button>
      </div>
    </div>
    <button type="submit" class="openGate" @click="openGate">开闸进场</button>
  </div>
</template>

<script>
export default {
  name: "enter",
  title: "无牌车扫码进场",
  data() {
    return {
      phone: "",
      code: "",
      locationId: "",
      parkCode: "",
    };
  },
  mounted() {
    const { locationId, parkCode } = this.$route.query;
    this.locationId = locationId;
    this.parkCode = parkCode;
  },
  methods: {
    getCode() {
      this.$http.post(
        "/park-service-user/park/userLogin/sendCode",
        { phone: this.phone, codeType: "noPlate" },
        (res) => {
          this.$toast("验证码发送成功，请注意查收");
        }
      );
    },
    openGate() {
      const { phone, code, locationId, parkCode } = this;
      this.$http.post(
        "/park-service-order/roadGateH5/insertRoadGateInRecord",
        { phone, code, locationId, parkCode },
        (res) => {
          this.$toast("开闸指令下发成功，请有序进场");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.plate {
  height: 100%;
  background-color: #fff;
  .parking {
    text-align: center;
    padding: 0.9rem 0 0.5rem 0;
    img {
      width: 6.2rem;
      height: auto;
    }
  }
  .forms {
    width: 6.5rem;
    margin: 0 auto;
    .item {
      display: flex;
      align-items: center;
    }
    input {
      display: block;
      width: 100%;
      height: 0.9rem;
      margin: 0.2rem auto;
      background: #f3f3f3;
      border-radius: 8px;
      border: 0;
      text-align: center;
      font-size: 0.3rem;
    }
    .getCode {
      display: block;
      width: 3rem;
      height: 0.9rem;
      margin-left: 0.4rem;
      background: #1ca6ff;
      border-radius: 8px;
      border: 0;
      font-size: 0.3rem;
      color: #fff;
    }
  }
  .openGate {
    display: block;
    width: 6.5rem;
    height: 0.9rem;
    margin: 1.4rem auto 0 auto;
    background: #1ca6ff;
    border-radius: 8px;
    border: 0;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
}
</style>